import React, { useState } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './novocliente.css';
import {mask, unMask} from 'remask';

import firebase from '../Config/firebase';


function NovoCliente(){

  const [nome, setNome] = useState('');
  const [fone, setFone] = useState('');
  const [contato, setContato] = useState('');
  const [endereco, setEndereco] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUF] = useState('');
  const [cep, setCEP] = useState('');
  const [cpfcnpj, setCpfCnpj] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();

  function CadastrarCliente(){

    if (nome.length === 0){
      setMensagem('Informe o nome');
    }
    else if (fone.length === 0){
      setMensagem('Informe o telefone');
    }
    else{
        db.collection('clientes').add({
          nome: nome,
          fone: fone,
          contato: contato,
          endereco: endereco,
          complemento: complemento,
          bairro: bairro,
          cidade: cidade,
          uf: uf,
          cep: cep,
          cpfcnpj: cpfcnpj
        }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
    }
return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        <div className='offset-lg-3 col-lg-6'>
          <h1>Novo cliente</h1>
          <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Nome</label>
                <input onChange={(e) => setNome(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Telefone</label>
                <input onChange={(e) => setFone(mask(unMask(e.target.value), ['(99) 9999-9999', '(99) 9 9999-9999']))} value={fone} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite o DDD e o número"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Contato</label>
                <input onChange={(e) => setContato(e.target.value)} type="contato" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Endereço</label>
                <input onChange={(e) => setEndereco(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Complemento</label>
                <input onChange={(e) => setComplemento(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Bairro</label>
                <input onChange={(e) => setBairro(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Cidade</label>
                <input onChange={(e) => setCidade(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">UF</label>
                <input onChange={(e) => setUF(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">CEP</label>
                <input onChange={(e) => setCEP(mask(unMask(e.target.value), ['99999-999']))} value={cep} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">CPF/ CNPJ</label>
                <input onChange={(e) => setCpfCnpj(mask(unMask(e.target.value), ['999.999.999-99', '99.999.999/9999-99']))} value={cpfcnpj} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
              </div>

              <div className='text-center'>
                <Link to="/app/home" className="btnCancelar btn btn-outline-primary">Cancelar</Link>
                <button onClick={CadastrarCliente} type="button" className="btnSalvar btn btn-primary">Salvar</button>
              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/cliente' /> : null}

          </form>
        </div>
      </div>
    </div>;  
  }

export default NovoCliente;