import React from 'react';
import { Link } from 'react-router-dom';
import './listaos.css';


    //  ESTA LINHA DE COMANDO É PARA EXCLUIR O ITEM DA LISTA
//        <Link to='#' onClick={() => props.clickDelete(os.id)}><i className='far fa-trash-alt icone-acao red'></i></Link>                        
function ListaOs(props){
    
  
    return <table className="table table-hover table-bordered">
            <thead>
                <tr className="table-secondary">
                    <th>Cliente</th>
                    <th>Número OS</th>
                    <th>Equipamento</th>
                    <th>Data Entrada</th>
                    <th>Data Saida</th>
                    <th className='col-acao-os'></th>
                </tr>
            </thead>
            <tbody>
                {
                  props.arrayOs.map((os) => {
                    return <tr key={os.id}>
                        <td>{os.cliente}</td>
                        <td>{os.numero}</td>
                        <td>{os.equipamento}</td>
                        <td>{os.datainicial}</td>
                        <td>{os.datafinal}</td>
                        <td>
                            <Link to={`/app/editaros/${os.id}${''}`}><i className='fas fa-edit icone-acao'></i></Link>                        
                            <Link to={`/app/pgto/${os.id}`}><i className='fas fa-money-bill icone-acao green'></i></Link>                      
                            <Link to={`/app/recibo/${os.id}`}><i className='fas fa-print icone-acao black'></i></Link>   
                            <Link to='#' onClick={() => props.clickDelete(os.id)}><i className='far fa-trash-alt icone-acao red'></i></Link>                        

                        </td>
                    </tr>
                    })
                }
            </tbody>
        </table>
}

export default ListaOs;
