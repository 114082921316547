import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar.jsx';
import ListaClientes from '../Components/ListaClientes/listacliente.jsx';
import './cliente.css';

import firebase from '../Config/firebase.js';
import 'firebase/firestore';

import SweetAlert from 'react-bootstrap-sweetalert';
import clientesPDF from '../Reports/Clientes/clientes.jsx';


function Cliente(){

  const [clientes, setClientes] = useState([]);
  const [busca, setBusca] = useState(''); // usar este sem o botão busca
  //  const [texto, setTexto] = useState(''); // usar este para o botão busca
  const [excluido, setExcluido] = useState('');
  const [confirmacao, setConfirmacao] = useState(false);
  const [confirmacaoId, setConfirmacaoId] = useState('');


function deleteUser(id){
  firebase.firestore().collection('clientes').doc(id).delete().then(() => {
    setExcluido(id);
    setConfirmacao(false);
  })
}

function confirmDeleteUser(id){
  setConfirmacaoId(id);
  setConfirmacao(true);
}

  const [novonr, setNovonr] = useState([]);
  const [buscaOS, setBuscaOS] = useState(''); // usar este sem o botão busca
  //  const [texto, setTexto] = useState(''); // usar este para o botão busca



 // funções para acessar a tabela NoSQL - Firebase
    useEffect(function(){
      let listaCli = [];

      firebase.firestore().collection('clientes').get().then(async function(resultado){
            await resultado.docs.forEach(function(doc){
              if (doc.data().nome.indexOf(busca) >= 0) {
                listaCli.push({
                    id: doc.id,
                    nome: doc.data().nome,
                    fone: doc.data().fone,
                    contato: doc.data().contato,
                    endereco: doc.data().endereco,
                    bairro: doc.data().bairro,
                    cidade: doc.data().cidade,
                    uf: doc.data().uf,
                    cep: doc.data().cep,
                    complemento: doc.data().complemento,
                    cpfcnpj: doc.data().cpfcnpj
                  });
                }
                listaCli.sort(function( a, b ) {
                  if (a.nome < b.nome) {
                    return -1;
                  } else {
                    return true;
                  }
                })
          })

            setClientes(listaCli);
        })
    }, [busca, excluido]);

    return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        <h2>Cadastro de Cliente</h2>

        <div className='row'>
          <div className='col-4'>
           <Link to='/app/novocliente' className="btnCliente btn btn-primary" type="button"><i className="fas fa-plus"></i> Cliente</Link>
           <button onClick={(e) => clientesPDF(clientes)} className=" btn-cli btn btn-danger" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Gerar PDF</button>
          </div>

          <div className='col-8'>
            <div className="input-group mb-3">
                <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
            </div>
          </div>
        </div>

        <ListaClientes arrayClientes={clientes} clickDelete={confirmDeleteUser}/>

       {
          confirmacao ? <SweetAlert 
                          warning
                          showCancel
                          showCloseButton
                          confirmBtnText='Sim'
                          confirmBtnBsStyle='danger'
                          cancelBtnText='Não'
                          cancelBtnBsStyle='light'
                          title='Exclusão'
                          onConfirm={() => deleteUser(confirmacaoId)}
                          onCancel={() => setConfirmacao(false)}
                          reverseButtons={true}
                        >
                          Deseja excluir o cliente selecionado?
                        </SweetAlert> : null}
      </div>
    </div>
  }
    
  
export default Cliente;