import React, { useState } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './novoos.css';
import {mask, unMask} from 'remask';

import firebase from '../Config/firebase';


function NovoOs(){

  const dt = new Date();
  const dia = String(dt.getDate()).padStart(2, '0');
  const mes = String(dt.getMonth() + 1).padStart(2, '0');
  const ano = dt.getFullYear();
  const dataAtual = `${dia}/${mes}/${ano}`;

  const hr = new Date();
  const hora = String(hr.getHours()).padStart(2, '0');
  const min = String(hr.getMinutes()).padStart(2, '0');
  const seg = String(hr.getSeconds()).padStart(2, '0');
  const horaAtual = `${hora}:${min}:${seg}`;


  const [cliente, setCliente] = useState('');
  const [acessorio, setAcessorio] = useState('');
  const [informado, setInformado] = useState('');
  const [datainicial, setDatainicial] = useState(dataAtual);
  const [horainicial, setHorainicial] = useState(horaAtual);
  const [equipamento, setEquipamento] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();


 function CadastrarOs(){

    if (cliente.length === 0){
      setMensagem('Informe o nome do cliente');
    }
    else if (numero.length === 0){
      setMensagem('Informe o número da OS');
    }
    else{
        db.collection('os').add({
          cliente: cliente,
          numero: numero,
          acessorio: acessorio,
          informado: informado,
          datainicial: datainicial,
          horainicial: horainicial,
          equipamento: equipamento,
          marca: marca,
          modelo: modelo,
          serie: serie
          }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
    }

return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        
        <div className='offset-lg-3 col-lg-6'>
          <h1>Nova Ordem de Serviço</h1>
          <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Cliente</label>
                  <input onChange={(e) => setCliente(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Número OS</label>
                <input onChange={(e) => setNumero(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Informado</label>
                <input onChange={(e) => setInformado(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Equipamento</label>
                <input onChange={(e) => setEquipamento(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Modelo</label>
                <input onChange={(e) => setModelo(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Marca</label>
                <input onChange={(e) => setMarca(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Acessórios</label>
                <input onChange={(e) => setAcessorio(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Série</label>
                <input onChange={(e) => setSerie(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de abertura</label>
                <input onChange={(e) => setDatainicial(e.target.value)} value={dataAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora da abertura</label>
                <input onChange={(e) => setHorainicial(e.target.value)} value={horaAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número" disabled/>
              </div>

              <div className='text-center'>
                <Link to="/app/os" className="btnCancelar btn btn-outline-primary">Cancelar</Link>
                <button onClick={CadastrarOs} type="button" className="btnSalvar btn btn-primary">Salvar</button>
              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/os' /> : null}

          </form>
        </div>
      </div>
    </div>;  
  }

export default NovoOs;