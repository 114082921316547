import React, { useState, useEffect } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import {mask, unMask} from 'remask';
import './pgto.css';

import firebase from '../Config/firebase';
import 'firebase/firestore';

import RecPagtoPDF from '../Reports/OsReciboPagto/osrecibopagto';


function Pgto(props){

    const dt = new Date();
    const dia = String(dt.getDate()).padStart(2, '0');
    const mes = String(dt.getMonth() + 1).padStart(2, '0');
    const ano = dt.getFullYear();
    const dataAtual = `${dia}/${mes}/${ano}`;
  
    const hr = new Date();
    const hora = String(hr.getHours()).padStart(2, '0');
    const min = String(hr.getMinutes()).padStart(2, '0');
    const seg = String(hr.getSeconds()).padStart(2, '0');
    const horaAtual = `${hora}:${min}:${seg}`;


  const [cliente, setCliente] = useState('');
  const [acessorio, setAcessorio] = useState('');
  const [equipamento, setEquipamento] = useState('');
  const [informado, setInformado] = useState('');
  const [constatado, setConstatado] = useState('');
  const [executado, setExecutado] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');
  const [datainicial, setDatainicial] = useState('');
  const [horainicial, setHorainicial] = useState('');
 
  const [formapagto, setFormapagto] = useState('');
  const [vrproduto, setVrproduto] = useState('');
  const [vrservico, setVrservico] = useState('');
  const [deslocamento, setDeslocamento] = useState('');
  const [desconto, setDesconto] = useState('');
  
  const calcular = parseFloat(vrproduto) + parseFloat(vrservico) + parseFloat(deslocamento) - parseFloat(desconto);
  
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  const [vrtotal, setVrtotal] = useState(calcular);
 
  const [datafinal, setDatafinal] = useState(dataAtual);
  const [horafinal, setHorafinal] = useState(horaAtual);
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();
   
  useEffect(() => {
    firebase.firestore().collection('os').doc(props.match.params.id).get().then((resultado) => {
        setCliente(resultado.data().cliente);
        setAcessorio(resultado.data().acessorio);
        setEquipamento(resultado.data().equipamento);
        setInformado(resultado.data().informado);
        setConstatado(resultado.data().constatado);
        setExecutado(resultado.data().executado);
        setMarca(resultado.data().marca);
        setModelo(resultado.data().modelo);
        setNumero(resultado.data().numero);
        setSerie(resultado.data().serie);
        setDatainicial(resultado.data().datainicial);
        setHorainicial(resultado.data().horainicial);
        setFormapagto(resultado.data().formapagto);
        setVrproduto(resultado.data().vrproduto);
        setVrservico(resultado.data().vrservico);
        setDeslocamento(resultado.data().deslocamento);
        setDesconto(resultado.data().desconto);
        setVrtotal(resultado.data().vrtotal);
    })
}, [props.match.params.id])

  function PagarOs(){

    if (cliente.length === 0){
      setMensagem('Informe o cliente');
    }
    else if (numero.length === 0){
      setMensagem('Informe o numero');
    }
    else{
        db.collection('os').doc(props.match.params.id).update({
          formapagto: formapagto,
          vrproduto: vrproduto,
          vrservico: vrservico,
          deslocamento: deslocamento,
          desconto: desconto,
          vrtotal: calcular,
          datafinal: datafinal,
          horafinal: horafinal,

        }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
    }

return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        
      <div className='offset-lg-3 col-lg-6'>
          <h1>Pagar OS</h1>

      <div className='row'>

          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Código</label>
                <input type="text" value={props.match.params.id} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
              </div>
          </div>

          <div className='col-4'>
            <div className="mb-3">
               <label htmlFor="exampleInputEmail1">Data entrada</label>
               <input onChange={(e) => setDatainicial(e.target.value)} value={datainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
            </div>
          </div>

          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora entrada</label>
                <input onChange={(e) => setHorainicial(e.target.value)} value={horainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
          </div>
     </div>

     <div className='row'>
       <div className='col-9'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Cliente</label>
              <input onChange={(e) => setCliente(e.target.value)} value={cliente} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
         </div>
       </div>
       <div className='col-3'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Número OS</label>
              <input onChange={(e) => setNumero(e.target.value)} value={numero} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
          </div>
        </div>
      </div>


      <div className='row'>

       <div className='col-4'>
         <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Equipamento</label>
                <input onChange={(e) => setEquipamento(e.target.value)} value={equipamento} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
         </div>
       </div>

       <div className='col-4'>
          <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Marca</label>
              <input onChange={(e) => setMarca(e.target.value)} value={marca} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
          </div>
        </div>


        <div className='col-4'>
            <div className="mb-3">
            <label htmlFor="exampleInputEmail1">Acessorio</label>
                <input onChange={(e) => setAcessorio(e.target.value)} value={acessorio} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
            </div>
        </div>

        </div>

        <div className='row'>
            <div className='col-4'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Modelo</label>
                    <input onChange={(e) => setModelo(e.target.value)} value={modelo} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                </div>
            </div>


            <div className='col-8'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Série</label>
                    <input onChange={(e) => setSerie(e.target.value)} value={serie} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                  </div>
            </div>
      </div>

        <form>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Informado</label>
                <textarea onChange={(e) => setInformado(e.target.value)} value={informado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>


              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Constatado</label>
                <textarea onChange={(e) => setConstatado(e.target.value)} value={constatado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>


              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Executado</label>
                <textarea onChange={(e) => setExecutado(e.target.value)} value={executado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>

              <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor do produto</label>
                    <input onChange={(e) => setVrproduto(mask(unMask(e.target.value), ['9.99', '99.99', '999.99', '9999.99']))} value={vrproduto} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>

               </div>

               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor do serviço</label>
                    <input onChange={(e) => setVrservico(mask(unMask(e.target.value), ['9.99', '99.99', '999.99', '9999.99']))} value={vrservico} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
               </div>


               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Deslocamento</label>
                    <input onChange={(e) => setDeslocamento(mask(unMask(e.target.value), ['9.99', '99.99', '999.99', '9999.99']))} value={deslocamento} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
               </div>


               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Desconto</label>
                    <input onChange={(e) => setDesconto(mask(unMask(e.target.value), ['9.99', '99.99', '999.99', '9999.99']))} value={desconto} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
               </div>


               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor Total</label>
                    <input onChange={(e) => setVrtotal(e.target.value)} value={formatter.format(calcular)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número" disabled/>
               </div>

               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Forma de pagamento</label>
                    <input onChange={(e) => setFormapagto(e.target.value)} value={formapagto} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
               </div>

               <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de fechamento</label>
                <input onChange={(e) => setDatafinal(e.target.value)} value={dataAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora de fechamento</label>
                <input onChange={(e) => setHorafinal(e.target.value)} value={horaAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número" disabled/>
              </div>

             
              <div className='text-center'>
                <Link to="/app/os" className="btnCancelar btn btn-outline-primary">Voltar</Link>
                <button onClick={PagarOs} type="button" className="btnSalvar btn btn-primary">Salvar</button>
                <button onClick={(e) => RecPagtoPDF(
                    cliente, 
                    acessorio, 
                    equipamento,
                    numero, 
                    informado,
                    constatado,
                    executado,
                    marca,
                    modelo,
                    serie,
                    datainicial,
                    horainicial,
                    formatter.format(vrproduto),
                    formatter.format(vrservico),
                    formatter.format(deslocamento),
                    formatter.format(desconto),
                    formatter.format(vrtotal),
                    formapagto,
                    datafinal,
                    horafinal
                    )} type="button" className="btnRecibo btn btn-danger">Imprimir Recibo</button>

              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/os' /> : null}

          </form>
        </div>
      </div>
    </div>;  
  }

export default Pgto;