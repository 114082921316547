import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function RecPagtoPDF( cliente, acessorio, equipamento, 
        numero, informado, constatado, executado, marca, 
        modelo, serie, datainicial, horainicial, vrproduto, 
        vrservico, deslocamento, desconto, vrtotal, formapagto, datafinal, horafinal, status){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;


    const reportTitle = [
        {
            text: 'Assistência Game&Cell',
			fontSize: 12,
			bold: true,
            margin:[15, 10, 0, 0] // lef, top, right, bottom
        },
        {
			text: 'Avenida Tâmara, 76 - Loja 4 - Centro - Carapicuíba - SP - 06320-020 - Fone: (11) 98183-3997', 
            fontSize: 9,
            margin:[15, 5, 0, 0] // lef, top, right, bottom
        },
        {
			text: 'franciscod2x@gmail.com', 
            fontSize: 8,
            margin:[15, 0, 0, 0] // lef, top, right, bottom
        }
    ];

    const details = [

        {text:'Comprovante de saída - Termo de garantia: ' + numero, 
        style: 'subheader',
        alignment: 'center',
        bold: true, 
        fontSize: 12,
        margin:[5, 10, 0, 10]
        },

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },

		{
			columns: [
				{
                    type: 'none',
					ul: ['Cliente: ' + cliente],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },

				{
                    type: 'none',
					ul: ['Data: ' + datainicial + ' - Hora: ' + horainicial ],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},

		{
			columns: [
				{
                    type: 'none',
					ul: ['Modelo: ' + modelo],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },

				{
                    type: 'none',
					ul: ['Marca: ' + marca],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},
		{
			columns: [
				{
                    type: 'none',
					ul: ['Acessório: ' + acessorio],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: ['Série: ' + serie],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
       
		'Problema informado:',
		{            
            type: 'none',
            ul: [informado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },

        'Problema Constatado:',
		{
            type: 'none',
            ul: [constatado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
		'Problema Executado:',
		{
            type: 'none',
            ul: [executado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
    
        {   
            text: '\nSOBRE A GARANTIA DO SERVIÇO', 
            style: 'header',
            alignment: 'center',
            bold: true, 
            fontSize: 12,
            margin:[5, 0, 0, 10]

       },
		{
			ol:[
                {
                    text: ['A empresa garante seus serviços por um período de 90 dias contra defeito de fabricação.'],
                },
                    'Excluem-se desta garantia defeitos causado por mau uso do equipamento, violação do lacre, manipulação de terceiros, mas condições de rede elétrica, quedas umidade, uso de acessórios não originais, instalação de software ou aplicativos de terceiros, etc. que possam provocar defeitos no aparelho, ou por incidentes de ordem física ou elétrica.',
                [
                    'A garantia estende-se apenas as peças e mão de obra e suas finalidades.',
    		    ],
            ],
            fontSize: 8,
            bold: true, 
		},

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },


        {
			columns: [{
                        type: 'none',
		            	ol:[
                            {
                                text: ['Data de entrada: ' + datafinal + ' - Hora de entrada: ' + horafinal],
                            },
                                '\nStatus da OS: Aguardando aprovação',
                            [
                                '\nTécnico responsável: Francisco',
                            ],
                        ],
                        fontSize: 10,
                    }
                ]
        },

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },

		{
			columns: [
				{
                    type: 'none',
					ul: ['Valor do produto:'],
                    fontSize: 10,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: [vrproduto],
                    fontSize: 10,
                    margin:[0, 0, 5, 10]
                },
			]

        },

		{
			columns: [
				{
                    type: 'none',
					ul: ['Valor do serviço:'],
                    fontSize: 10,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: [vrservico],
                    fontSize: 10,
                    margin:[0, 0, 5, 10]
                },
			]

        },

		{
			columns: [
				{
                    type: 'none',
					ul: ['Deslocamento: '],
                    fontSize: 10,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: [deslocamento],
                    fontSize: 10,
                    margin:[0, 0, 5, 10]
                },
			]

        },
		{
			columns: [
				{
                    type: 'none',
					ul: ['Desconto: '],
                    fontSize: 10,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: [desconto],
                    fontSize: 10,
                    margin:[0, 0, 5, 10]
                },
			]

        },
		{
			columns: [
				{
                    type: 'none',
					ul: ['Valor total: '],
                    fontSize: 10,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: [vrtotal],
                    fontSize: 10,
                    margin:[0, 0, 5, 10]
                },
			]

        },

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },

        {
            text: '\n\n', style: 'header',
            margin:[0, 10, 0, 0]
        },

        {
			columns: [
				{
                    type: 'none',
					ul: [
						'____________________________',
						'Visto: Assitência Game&Cell',
					]
				},
				{
                    type: 'none',
					ul: [
						'____________________________',
						'Visto: Cliente',
					]
				}
			]
		},

    ];
function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin:[0, 10, 20, 0]
            }

        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        content: [details],
        header: [reportTitle],
        footer: Rodape
    }

        pdfMake.createPdf(docDefinitios).download();
}

export default RecPagtoPDF;