import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar.jsx';
import ListaOs from '../Components/ListaOs/listaos.jsx';
import './os.css';

import firebase from '../Config/firebase.js';
import 'firebase/firestore';

import SweetAlert from 'react-bootstrap-sweetalert';
import osPDF from '../Reports/OrdemServico/os.jsx';

function Os(){
  
  const [os, setOs] = useState([]);
  const [nros, setNros] = useState('');
  const [busca, setBusca] = useState(''); // usar este sem o botão busca
  const [excluido, setExcluido] = useState('');
  const [confirmacao, setConfirmacao] = useState(false);
  const [confirmacaoId, setConfirmacaoId] = useState('');


function deleteUser(id){
  firebase.firestore().collection('os').doc(id).delete().then(() => {
    setExcluido(id);
    setConfirmacao(false);
  })
}

function confirmDeleteUser(id){
  setConfirmacaoId(id);
  setConfirmacao(true);
}

    // funções para acessar a tabela NoSQL - Firebase
 
    useEffect(function(){
      let listaCli = [];

      firebase.firestore().collection('os').get().then(async function(resultado){
            await resultado.docs.forEach(function(doc){
              if (doc.data().numero.indexOf(busca) >= 0) {
                listaCli.push({
                  id: doc.id,
                    acessorio: doc.data().acessorio,
                    cliente: doc.data().cliente,
                    constatado: doc.data().constatado,
                    datainicial: doc.data().datainicial,
                    datafinal: doc.data().datafinal,
                    desconto: doc.data().desconto,
                    deslocamento: doc.data().deslocamento,
                    equipamento: doc.data().equipamento,
                    executado: doc.data().executado,
                    formapagto: doc.data().formapagto,
                    horainicial: doc.data().horainicial,
                    horafinal: doc.data().horafinal,
                    informado: doc.data().informado,
                    marca: doc.data().executado,
                    modelo: doc.data().executado,
                    numero: doc.data().numero,
                    responsavel: doc.data().executado,
                    serie: doc.data().executado,
                    status: doc.data().executado,
                    vrprotuto: doc.data().executado,
                    vrservico: doc.data().executado,
                    vrtotal: doc.data().executado
                  });
                }

                
                // neste ponto o sistema organiza por ordem decrescente
                  listaCli.sort(function( a, b ) {
                    if (a.numero > b.numero) {
                      return -1;
                    } else {
                      return true;
                    }
                  })
                })
            setOs(listaCli);
          })
    }, [busca, excluido]);

    return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
      <h2>Ordem de Serviço</h2>

        <div className='row'>
          <div className='col-4'>
           <button onClick={(e) => osPDF(os)} className="btn-cli btn btn-danger" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Relatório Geral</button>
          </div>

          <div className='col-8'>
            <div className="input-group mb-3">
                <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por número de OS" aria-describedby="button-addon2" />
            </div>

          </div>

        </div>

        <ListaOs arrayOs={os} clickDelete={confirmDeleteUser}/>

        {
          confirmacao ? <SweetAlert 
                          warning
                          showCancel
                          showCloseButton
                          confirmBtnText='Sim'
                          confirmBtnBsStyle='danger'
                          cancelBtnText='Não'
                          cancelBtnBsStyle='light'
                          title='Exclusão'
                          onConfirm={() => deleteUser(confirmacaoId)}
                          onCancel={() => setConfirmacao(false)}
                          reverseButtons={true}
                        >
                          Deseja finalizar este atendimento?
                        </SweetAlert> : null}
      </div>
    </div>
  }

export default Os;