import React, { useState, useEffect } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './editaros.css';

import firebase from '../Config/firebase';
import 'firebase/firestore';

import RecPDF from '../Reports/OsRecibo/osrecibo';

function EditarOs(props){

  const [cliente, setCliente] = useState('');
  const [acessorio, setAcessorio] = useState('');
  const [equipamento, setEquipamento] = useState('');
  const [informado, setInformado] = useState('');
  const [constatado, setConstatado] = useState('');
  const [executado, setExecutado] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');
  const [datainicial, setDatainicial] = useState('');
  const [horainicial, setHorainicial] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();



  useEffect(() => {
    firebase.firestore().collection('os').doc(props.match.params.id).get().then((resultado) => {
        setCliente(resultado.data().cliente);
        setAcessorio(resultado.data().acessorio);
        setEquipamento(resultado.data().equipamento);
        setInformado(resultado.data().informado);
        setConstatado(resultado.data().constatado);
        setExecutado(resultado.data().executado);
        setMarca(resultado.data().marca);
        setModelo(resultado.data().modelo);
        setNumero(resultado.data().numero);
        setSerie(resultado.data().serie);
        setDatainicial(resultado.data().datainicial);
        setHorainicial(resultado.data().horainicial);
    })
}, [props.match.params.id])

  function AlterarOs(){

    if (cliente.length === 0){
      setMensagem('Informe o cliente');
    }
    else if (numero.length === 0){
      setMensagem('Informe o numero');
    }
    else{
        db.collection('os').doc(props.match.params.id).update({
          cliente: cliente,
          acessorio: acessorio,
          equipamento: equipamento,
          informado: informado,
          constatado: constatado,
          executado: executado,
          marca: marca,
          modelo: modelo,
          serie: serie,
          datainicial: datainicial,
          horainicial: horainicial,
        }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
    }

return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        
      <div className='offset-lg-3 col-lg-6'>
          <h1>Editar OS</h1>

      <div className='row'>

          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Código</label>
                <input type="text" value={props.match.params.id} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
              </div>
          </div>

          <div className='col-4'>
            <div className="mb-3">
               <label htmlFor="exampleInputEmail1">Data entrada</label>
               <input onChange={(e) => setDatainicial(e.target.value)} value={datainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
            </div>
          </div>

          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora entrada</label>
                <input onChange={(e) => setHorainicial(e.target.value)} value={horainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
          </div>
     </div>

     <div className='row'>
       <div className='col-9'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Cliente</label>
              <input onChange={(e) => setCliente(e.target.value)} value={cliente} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
         </div>
       </div>
       <div className='col-3'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Número OS</label>
              <input onChange={(e) => setNumero(e.target.value)} value={numero} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
          </div>
        </div>
      </div>


      <div className='row'>

       <div className='col-4'>
         <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Equipamento</label>
                <input onChange={(e) => setEquipamento(e.target.value)} value={equipamento} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
         </div>
       </div>

       <div className='col-4'>
          <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Marca</label>
              <input onChange={(e) => setMarca(e.target.value)} value={marca} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
          </div>
        </div>


        <div className='col-4'>
            <div className="mb-3">
            <label htmlFor="exampleInputEmail1">Acessorio</label>
                <input onChange={(e) => setAcessorio(e.target.value)} value={acessorio} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
            </div>
        </div>

        </div>

        <div className='row'>
            <div className='col-4'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Modelo</label>
                    <input onChange={(e) => setModelo(e.target.value)} value={modelo} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                </div>
            </div>


            <div className='col-8'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Série</label>
                    <input onChange={(e) => setSerie(e.target.value)} value={serie} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
                  </div>
            </div>
      </div>

        <form>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Informado</label>
                <textarea onChange={(e) => setInformado(e.target.value)} value={informado} type="text" class="form-control" id="validationTextarea"></textarea>
              </div>


              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Constatado</label>
                <textarea onChange={(e) => setConstatado(e.target.value)} value={constatado} type="text" class="form-control" id="validationTextarea"></textarea>
              </div>


              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Executado</label>
                <textarea onChange={(e) => setExecutado(e.target.value)} value={executado} type="text" class="form-control" id="validationTextarea"></textarea>
              </div>

             
              <div className='text-center'>
                <Link to="/app/os" className="btnCancelar btn btn-outline-primary">Voltar</Link>
                <button onClick={AlterarOs} type="button" className="btnSalvar btn btn-primary">Salvar</button>
                <button onClick={(e) => RecPDF(
                    cliente, 
                    acessorio, 
                    equipamento,
                    numero, 
                    informado,
                    constatado,
                    executado,
                    marca,
                    modelo,
                    serie,
                    datainicial,
                    horainicial,
                    )} type="button" className="btnRecibo btn btn-danger">Imprimir Recibo</button>

              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/os' /> : null}

          </form>
        </div>
      </div>
    </div>;  
  }

export default EditarOs;