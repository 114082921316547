import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'


function RecPDF( cliente, acessorio, equipamento, 
        numero, informado, constatado, executado, marca, 
        modelo, serie, datainicial, horainicial, vrproduto, 
        vrservico, deslocamento, desconto, vrtotal, formapagto, datafinal, horafinal, status){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            columns: [
                      {
                        text: 'Logo'
                      },
    
                      {
                        text: 'Assistência Game&Cell',
                        fontSize: 12,
                        bold: true,
                        margin:[15, 10, 0, 0] // lef, top, right, bottom
                      },
    
                      {

                      },
    
                      {

                    }


                    ]
        },
        
        {
			text: 'Avenida Tâmara, 76 - Loja 4 - Centro - Carapicuíba - SP - 06320-020 - Fone: (11) 98183-3997', 
            fontSize: 9,
            margin:[15, 5, 0, 0] // lef, top, right, bottom
        },
        {
			text: 'franciscod2x@gmail.com', 
            fontSize: 8,
            margin:[15, 0, 0, 0] // lef, top, right, bottom
        }
    ];

    const details = [

        {
            text:'Entrada da Ordem de serviço número: ' + numero, 
            style: 'subheader',
            alignment: 'center',
            bold: true, 
            fontSize: 12,
            margin:[5, 10, 0, 10]
        },

        {
            text: '_______________________________________________________________________________________________________', 
            margin:[0, 0, 0, 10]
        },

		{
			columns: [
				{
                    type: 'none',
					ul: ['Cliente: ' + cliente],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },

				{
                    type: 'none',
					ul: ['Data: ' + datainicial + ' - Hora: ' + horainicial ],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},

		{
			columns: [
				{
                    type: 'none',
					ul: ['Modelo: ' + modelo],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },

				{
                    type: 'none',
					ul: ['Marca: ' + marca],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},
		{
			columns: [
				{
                    type: 'none',
					ul: ['Acessório: ' + acessorio],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
				{
                    type: 'none',
					ul: ['Série: ' + serie],
                    fontSize: 9,
                    margin:[0, 0, 0, 10]
                },
			]
		},

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
       
		'Problema informado:',
		{            
            type: 'none',
            ul: [informado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },

        'Problema Constatado:',
		{
            type: 'none',
            ul: [constatado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
		'Problema Executado:',
		{
            type: 'none',
            ul: [executado], fontSize: 10, margin:[0, 5, 0, 10] 
        },
        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },
    
        {   
            text: '\nCONDIÇÕES GERAIS', 
            style: 'header',
            alignment: 'center',
            bold: true, 
            fontSize: 12,
            margin:[5, 0, 0, 10]

       },
		{
			ol:[
                {
                    text: ['A garantia estende-se apenas as peças e mão-de-obra utilizadas e suas finalidades.'],
                },
                    'Defeitos ocultos, identificados após abertura serão orçados a parte.',
                [
                    'É obrigatório a apresentação deste documento ao retirar o equipamento.',
    		    ],
                [
                'Aparelhos não retirados no prazo de 30 (trinta) dias, implicará na cobrança de multa de 30% sobre o valor total do orçamento, mais diária de R$ 5,00 (cinco reais) ao dia referente a estadia. Após 180 dias o mesmo será sucarteado LEI 4668/16.',
                ],
                [
                'É de total responsabilidade do cliente as informações contidas na ordem de serviço, falta de informações ou informações falsas. O mesmo será devolvido.',
                ],
            ],
            fontSize: 8,
            bold: true, 
		},

        {text: '_______________________________________________________________________________________________________', 
        margin:[0, 0, 0, 10]
        },


        {
			columns: [{
                        type: 'none',
		            	ol:[
                            {
                                text: ['Data de entrada: ' + datainicial + ' - Hora de entrada: ' + horainicial],
                            },
                                '\nStatus da OS: Aguardando aprovação',
                            [
                                '\nTécnico responsável: Francisco',
                            ],
                        ],
                        fontSize: 10,
                    }
                ]
        },

        {
            text: '\n\n', style: 'header',
            margin:[0, 10, 0, 0]
        },

        {
			columns: [
				{
                    type: 'none',
					ul: [
						'____________________________',
						'Visto: Assitência Game&Cell',
					]
				},
				{
                    type: 'none',
					ul: [
						'____________________________',
						'Visto: Cliente',
					]
				}
			]
		},

    ];
function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin:[0, 10, 20, 0]
            }

        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        content: [details],
        header: [reportTitle],
        footer: Rodape
    }

        pdfMake.createPdf(docDefinitios).download();
}

export default RecPDF;