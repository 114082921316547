import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar.jsx';
import './uploadsimages.css';

import atendimentoPDF from '../Reports/Atendimento/atendimento.jsx';

function UploadsImages(){


    return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        <h2>Ordem de serviço</h2>

        <div className='row'>
          <div className='col-4'>
           <Link to='#' className="btnAtendimento btn btn-primary" type="button"><i className="fas fa-plus"></i> Novo</Link>
           <button className="btn-cli btn btn-danger" type="button" id="button-addon2"><i className="far fa-file-pdf"></i> Gerar PDF</button>
          </div>

          <div className='col-8'>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
            </div>
          </div>
        </div>
      </div>
    </div>
  }

export default UploadsImages;