import React from 'react';
import { Link } from 'react-router-dom';
import './listacliente.css';


function ListaClientes(props){

    return <table className="table table-hover table-bordered">
        <thead>
            <tr className="table-secondary">
                <th scope="col">Cliente</th>
                <th scope="col">Telefone</th>
                <th scope="col">Contato</th>
                <th scope="col">Endereço</th>
                <th scope="col">Bairro</th>
                <th scope="col" className='col-acao-cliente'></th>
            </tr>
        </thead>
        <tbody>

            {
                props.arrayClientes.map((cliente) => {
                   return <tr key={cliente.id}>
                    <td>{cliente.nome}</td>
                    <td>{cliente.fone}</td>
                    <td>{cliente.contato}</td>
                    <td>{cliente.endereco}</td>
                    <td>{cliente.bairro}</td>
                    <td>
                        <Link to={`/app/editarcliente/${cliente.id}`}><i className='fas fa-edit icone-acao'></i></Link>                        
                        <Link to={`/app/atalhoos/${cliente.nome}`}><i className='fas fa-clipboard icone-acao grey'></i></Link>                        
                        <Link to='#' onClick={() => props.clickDelete(cliente.id)}><i className='far fa-trash-alt icone-acao red'></i></Link>                        
                    </td>
                </tr>
     
                })
            }

        </tbody>
        </table>
}

export default ListaClientes;