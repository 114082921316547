import firebase from 'firebase/app';

const firebaseConfig = {

  apiKey: "AIzaSyCie4OmdRy4u3EtVAf2fadZHp_Ei_fn85k",
  authDomain: "game-cell.firebaseapp.com",
  projectId: "game-cell",
  storageBucket: "game-cell.appspot.com",
  messagingSenderId: "205397304198",
  appId: "1:205397304198:web:8dd19f7237763d76918207",
  measurementId: "G-ED78YFSMEJ"
};

  
  // Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
