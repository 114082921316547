import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './dashboard.css';
import Navbar from '../Components/Navbar/navbar.jsx';
import imgDashboard from '../Images/dashboard_geral.png'

import 'firebase/firestore';

function Dashboard(){


  return <div>
  <Navbar/>
  <div className='container-fluid titulo'>
     <img className='dash_geral' src={imgDashboard} alt="NS CRM" />                        
  </div>
</div>
}


export default Dashboard;