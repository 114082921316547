import React from 'react';
import { Link } from 'react-router-dom';
import './listaatendimento.css';


function ListaAtendimento(props){

    return <table className="table table-hover table-bordered">
        <thead>
            <tr className="table-secondary">
                <th scope="col">Nova OS</th>
                <th scope="col" className='col-acao-atendimento'></th>
            </tr>
        </thead>
        <tbody>

            {
                props.arrayAtendimentos.map((nros) => {
                   return <tr key={nros.id}>
                    <td>{nros.novonr++}</td>
                    <td>
                        <Link to={`/app/editaratendimento/${nros.novonr}`}><i className='fas fa-edit icone-acao'></i></Link>                        
                        <Link to='#' onClick={() => props.clickDelete(nros.id)}><i className='far fa-trash-alt icone-acao red'></i></Link>                        
                    </td>
                </tr>

                })
            }

        </tbody>
        </table>
}
export default ListaAtendimento;