import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function osPDF(os){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Assistência Game&Cell',
			fontSize: 12,
			bold: true,
            margin:[15, 10, 0, 0] // lef, top, right, bottom
        },
        {
			text: 'Avenida Tâmara, 76 - Loja 4 - Centro - Carapicuíba - SP - 06320-020 - Fone: (11) 4553-4572', 
            fontSize: 9,
            margin:[15, 5, 0, 0] // lef, top, right, bottom
        },
        {
			text: 'franciscod2x@gmail.com', 
            fontSize: 8,
            margin:[15, 0, 0, 0] // lef, top, right, bottom
        }
    ];

    const dados = os.map((os) => {
        return [
//            {text: cliente.id, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: os.numero, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.cliente, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.equipamento, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.datainicial, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.datafinal, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.informado, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.constatado, fontSize: 6, margin: [0, 0, 0, 2]},
            {text: os.executado, fontSize: 6, margin: [0, 0, 0, 2]},
        ]
    });

    const details = [
        {
            table: {
                headerRows: 1,
                body: [
                    [
                        {text: 'Número OS', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Nome', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Equipamento', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Data de entrada', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Data de saída', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Problema informado', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Problema constatado', fontSize: 8, margin: [0, 10, 0, 2]},
                        {text: 'Problema executado', fontSize: 8, margin: [0, 10, 0, 2]}
                    ],
                    ...dados
                ]
            },
            layout:'headerLineOnly' //'lightHorizontalLines' //headerLineOnly outro tipo de estilo de relatótio
            
        },

    ];
function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin:[0, 10, 20, 0]
            }

        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        content: [details],
        header: [reportTitle],
        footer: Rodape
    }

        pdfMake.createPdf(docDefinitios).download();
}

export default osPDF;