import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/auth';

import './navbar.css';



function Navbar(){

    const {setLogado} = useContext(AuthContext);

    function Logout(){
        setLogado(false);
        localStorage.removeItem("logado");
    }

    return <nav className="navbar navbar-expand navbar-dark">
    <div className='container-fluid'>
            <a className="navbar-brand" href="/#index.html"> 
            <img src="/Images/logo.png" alt="Game-Cell" height="50"/>
            </a>
            <button className="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <Link to="/app/home" className="nav-link" aria-current="page" >Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/app/cliente" className="nav-link" aria-current="page" >Cliente</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/app/os" className="nav-link" aria-current="page" >Ordem de Serviço</Link>
                    </li>
                    <li className="nav-item">
                        <a href="#" onClick={Logout} className="nav-link logout" aria-current="page" >Sair</a>
                    </li>
                </ul>
            </div>
    </div>
 </nav>;
}

export default Navbar;
