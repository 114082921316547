import React, { useState, useEffect } from 'react';
import ListaAtendimento from '../Components/ListaAtendimento/listaatendimento.jsx';
import './atendimento.css';

import firebase from '../Config/firebase.js';
import 'firebase/firestore';


function Atendimento(){
  const [novonr, setNovonr] = useState([]);
  const [buscaOS, setBuscaOS] = useState(''); // usar este sem o botão busca



        // funções para acessar a tabela NoSQL - Firebase

        useEffect(function(){
          let listaOS = [];
    
          firebase.firestore().collection('nros').get().then(async function(resultado){
                await resultado.docs.forEach(function(doc){
                  if (doc.data().novonr.indexOf(buscaOS) >= 0) {
                    listaOS.push({
                        id: doc.id,
                        novonr: doc.data().novonr
                      });
    
                    }
                })
    
                setNovonr(listaOS);
            })
        }, [buscaOS]);
    
        return <div><ListaAtendimento arrayAtendimentos={novonr}/></div>

  }

export default Atendimento;