import React, { useState } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './novoatendimento.css';
import {mask, unMask} from 'remask';

import firebase from '../Config/firebase';


function NovoAtendimento(){

  const [cliente, setCliente] = useState('');
  const [chamado, setChamado] = useState('');
  const [canal, setCanal] = useState('');
  const [dataabertura, setDataabertura] = useState('');
  const [solucao, setSolucao] = useState('');
  const [datafechamento, setDatafechamento] = useState('');
  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();

  function CadastrarAtendimento(){

    if (cliente.length === 0){
      setMensagem('Informe o nome do cliente');
    }
    else if (chamado.length === 0){
      setMensagem('Informe o tipo de chamado');
    }
    else{
        db.collection('atendimento').add({
          cliente: cliente,
          chamado: chamado,
          canal: canal,
          dataabertura: dataabertura,
          datafechamento: datafechamento,
          solucao: solucao
          }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
    }
 
return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        
        <div className='offset-lg-3 col-lg-6'>
          <h1>Novo atendimento</h1>
          <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Cliente</label>
                <input onChange={(e) => setCliente(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Chamado</label>
                <input onChange={(e) => setChamado(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Canal de contato</label>
                <input onChange={(e) => setCanal(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de abertura</label>
                <input onChange={(e) => setDataabertura(mask(unMask(e.target.value), ['99/99/9999 - 99:99:99']))} value={dataabertura} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
              </div>

              <div className="form-group">
                <label for="exampleFormControlSelect1">Solução</label>
                <select onChange={(e) => setSolucao(e.target.value)} value={solucao} type="text"  className="form-control" id="exampleFormControlSelect1" >
                    <option>0 - Selecione</option>
                    <option>1 - Aguardando retorno</option>
                    <option>2 - Aguardando pagto</option>
                    <option>3 - Em preocesso</option>
                    <option>4 - Em separação</option>
                    <option>5 - Entregue</option>
                </select>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de fechamento</label>
                <input onChange={(e) => setDatafechamento(mask(unMask(e.target.value), ['99/99/9999 - 99:99:99']))} value={datafechamento} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número"/>
              </div>

              <div className='text-center'>
                <Link to="/app/atendimento" className="btnCancelar btn btn-outline-primary">Cancelar</Link>
                <button onClick={CadastrarAtendimento} type="button" className="btnSalvar btn btn-primary">Salvar</button>
              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/atendimento' /> : null}

          </form>
        </div>
      </div>
    </div>;  
  }

export default NovoAtendimento;