import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar.jsx';
import './home.css';
import imgCliente from '../Images/cliente.png'
import imgOS from '../Images/os.png'

function Home(){
    return <div>
      <Navbar/>
      <h2 className='tituloHome'>Painel de controle</h2>
      <div className='container'>

        <div className='row'>
          <div className='col-4'>
              <Link to="/app/cliente" className="nav-link" aria-current="page" >
                <img className="imgCliente" src={imgCliente}/>
                <h2>Cliente</h2>
              </Link>
          </div>


          <div className='col-4'>
                <Link to="/app/os" className="nav-link" aria-current="page" >
                  <img className="imgOS" src={imgOS}/>
                  <h2>Ordem de Serviço</h2>
                </Link>

          </div>

        </div>
      </div>
    </div>
  }

export default Home;