import React, { useState, useEffect } from 'react';

import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './atalhoos.css';

import firebase from '../Config/firebase';
import 'firebase/firestore';



function AtalhoOs(props){

  const [mensagem, setMensagem] = useState('');
  const [sucesso, setSucesso] = useState('N');
  const db = firebase.firestore();

  
 
  const dt = new Date();
  const dia = String(dt.getDate()).padStart(2, '0');
  const mes = String(dt.getMonth() + 1).padStart(2, '0');
  const ano = dt.getFullYear();
  const dataAtual = `${dia}/${mes}/${ano}`;

  const hr = new Date();
  const hora = String(hr.getHours()).padStart(2, '0');
  const min = String(hr.getMinutes()).padStart(2, '0');
  const seg = String(hr.getSeconds()).padStart(2, '0');
  const horaAtual = `${hora}:${min}:${seg}`;

  const meunome = props.match.params.id;
 
  
  const [cliente, setCliente] = useState(meunome);
  const [acessorio, setAcessorio] = useState('');
  const [informado, setInformado] = useState('');
  const [datainicial, setDatainicial] = useState(dataAtual);
  const [horainicial, setHorainicial] = useState(horaAtual);
  const [equipamento, setEquipamento] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [serie, setSerie] = useState('');

  const [os, setOs] = useState([]);


  const [numero, setNumero] = useState('');

  useEffect(function(){
    let listaOS = [''];
  
    firebase.firestore().collection('os').get().then(async function(resultado)
        {
          await resultado.docs.forEach(function(doc){{listaOS.push({cliente: doc.data().cliente});
        }
            
              })
          setOs(listaOS);
        })
  }, []);


  function CadastrarOs(){
  
    if (cliente.length === 0){
      setMensagem('Informe o nome do cliente');
    }
    else if (os.length === 0){
      setMensagem('Informe o número da OS');
    }
    else{
        db.collection('os').add({
          cliente: cliente,
          numero: String(os.length),
          acessorio: acessorio,
          informado: informado,
          datainicial: datainicial,
          horainicial: horainicial,
          equipamento: equipamento,
          marca: marca,
          modelo: modelo,
          serie: serie,
          constatado: '',
          datatfinal: '',
          desconto: '0.00',
          deslocamento: '0.00',
          executado: '',
          formapgto: '',
          horafinal: '',
          responsavel: 'Francisco',
          status: 'Aguardando aprovação',
          vrproduto: '0.00',
          vrservico: '0.00',
          vrtotal: '0.00'
          }).then(() => {
          setMensagem('');
          setSucesso('S');
        }).catch((erro) => {
          setMensagem(erro);
          setSucesso('N');
        })
      }
}


return <div>
      <Navbar/>
      <div className='container-fluid titulo'>
        
        <div className='offset-lg-3 col-lg-6'>
          <h1>Nova ordem de serviço = {os.length}</h1>
          <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Cliente</label>
                <input onChange={(e) => setCliente(e.target.value)} value={cliente} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Número OS</label>
                <input onChange={(e) => setNumero(e.target.value)} value={os.length} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Informado</label>
                <textarea onChange={(e) => setInformado(e.target.value)} class="form-control" id="validationTextarea"></textarea>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Equipamento</label>
                <input onChange={(e) => setEquipamento(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Modelo</label>
                <input onChange={(e) => setModelo(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Marca</label>
                <input onChange={(e) => setMarca(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Acessórios</label>
                <input onChange={(e) => setAcessorio(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Série</label>
                <input onChange={(e) => setSerie(e.target.value)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de abertura</label>
                <input onChange={(e) => setDatainicial(e.target.value)} value={dataAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora da abertura</label>
                <input onChange={(e) => setHorainicial(e.target.value)} value={horaAtual} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número" disabled/>
              </div>

              <div className='text-center'>
                <Link to="/app/cliente" className="btnCancelar btn btn-outline-primary">Cancelar</Link>
                <button onClick={CadastrarOs} type="button" className="btnSalvar btn btn-primary">Salvar</button>
              </div> 

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Redirect to = '/app/os' /> : null}

          </form>
        </div>
      </div>
    </div>  
 }

export default AtalhoOs;