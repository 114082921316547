import React, { useState, useEffect } from 'react';
import {Link, Redirect}  from 'react-router-dom';
import Navbar from '../Components/Navbar/navbar';
import './recibo.css';

import firebase from '../Config/firebase';
import 'firebase/firestore';

import RecPagtoPDF from '../Reports/OsReciboPagto/osrecibopagto';

function Recibo(props){

  const [os, setOs] = useState([]);
  const [cliente, setCliente] = useState('');
  const [acessorio, setAcessorio] = useState('');
  const [equipamento, setEquipamento] = useState('');
  const [informado, setInformado] = useState('');
  const [constatado, setConstatado] = useState('');
  const [executado, setExecutado] = useState('');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [numero, setNumero] = useState('');
  const [serie, setSerie] = useState('');
  const [datainicial, setDatainicial] = useState('');
  const [horainicial, setHorainicial] = useState('');
  const [vrproduto, setVrproduto] = useState('');
  const [vrservico, setVrservico] = useState('');
  const [deslocamento, setDeslocamento] = useState('');
  const [desconto, setDesconto] = useState('');
  const [vrtotal, setVrtotal] = useState('');
  const [formapagto, setFormapagto] = useState('');
  const [datafinal, setDatafinal] = useState('');
  const [horafinal, setHorafinal] = useState('');
  const db = firebase.firestore();

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });

  useEffect(() => {
    firebase.firestore().collection('os').doc(props.match.params.id).get().then((resultado) => {
        setCliente(resultado.data().cliente);
        setAcessorio(resultado.data().acessorio);
        setEquipamento(resultado.data().equipamento);
        setInformado(resultado.data().informado);
        setConstatado(resultado.data().constatado);
        setExecutado(resultado.data().executado);
        setMarca(resultado.data().marca);
        setModelo(resultado.data().modelo);
        setNumero(resultado.data().numero);
        setSerie(resultado.data().serie);
        setDatainicial(resultado.data().datainicial);
        setHorainicial(resultado.data().horainicial);
        setVrproduto(resultado.data().vrproduto);
        setVrservico(resultado.data().vrservico);
        setDeslocamento(resultado.data().deslocamento);
        setDesconto(resultado.data().desconto);
        setVrtotal(resultado.data().vrtotal);
        setFormapagto(resultado.data().formapagto);
        setDatafinal(resultado.data().datafinal);
        setHorafinal(resultado.data().horafinal);
    })
}, [props.match.params.id])

return <div>
      <Navbar/>
      <div className='container-fluid titulo'>        
      <div className='offset-lg-3 col-lg-6'>
          <h1>Recibo OS</h1>
      <div className='row'>
          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Código</label>
                <input type="text" value={props.match.params.id} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
              </div>
          </div>
          <div className='col-4'>
            <div className="mb-3">
               <label htmlFor="exampleInputEmail1">Data entrada</label>
               <input onChange={(e) => setDatainicial(e.target.value)} value={datainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
            </div>
          </div>
          <div className='col-4'>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora entrada</label>
                <input onChange={(e) => setHorainicial(e.target.value)} value={horainicial} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
          </div>
     </div>
     <div className='row'>
       <div className='col-9'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Cliente</label>
              <input onChange={(e) => setCliente(e.target.value)} value={cliente} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
         </div>
       </div>
       <div className='col-3'>
         <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Número OS</label>
              <input onChange={(e) => setNumero(e.target.value)} value={numero} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
          </div>
        </div>
      </div>
      <div className='row'>
       <div className='col-4'>
         <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Equipamento</label>
                <input onChange={(e) => setEquipamento(e.target.value)} value={equipamento} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
         </div>
       </div>
       <div className='col-4'>
          <div className="mb-3">
              <label htmlFor="exampleInputEmail1">Marca</label>
              <input onChange={(e) => setMarca(e.target.value)} value={marca} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
          </div>
        </div>
        <div className='col-4'>
            <div className="mb-3">
            <label htmlFor="exampleInputEmail1">Acessorio</label>
                <input onChange={(e) => setAcessorio(e.target.value)} value={acessorio} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
            </div>
        </div>
        </div>
        <div className='row'>
            <div className='col-4'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Modelo</label>
                    <input onChange={(e) => setModelo(e.target.value)} value={modelo} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                </div>
            </div>
            <div className='col-8'>
                <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Série</label>
                    <input onChange={(e) => setSerie(e.target.value)} value={serie} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
                  </div>
            </div>
      </div>
        <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Informado</label>
                <textarea onChange={(e) => setInformado(e.target.value)} value={informado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Constatado</label>
                <textarea onChange={(e) => setConstatado(e.target.value)} value={constatado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Problema Executado</label>
                <textarea onChange={(e) => setExecutado(e.target.value)} value={executado} type="text" class="form-control" id="validationTextarea" disabled />
              </div>
              <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor do produto</label>
                    <input onChange={(e) => setVrproduto(e.target.value)} value={formatter.format(vrproduto)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
               </div>
               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor do serviço</label>
                    <input onChange={(e) => setVrservico(e.target.value)} value={formatter.format(vrservico)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
               </div>
               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Deslocamento</label>
                    <input onChange={(e) => setDeslocamento(e.target.value)} value={formatter.format(deslocamento)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
               </div>
               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Desconto</label>
                    <input onChange={(e) => setDesconto(e.target.value)} value={formatter.format(desconto)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
               </div>
               <div className="mb-3">
                    <label htmlFor="exampleInputEmail1">Valor Total</label>
                    <input onChange={(e) => setVrtotal(e.target.value)} value={formatter.format(vrtotal)} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite apenas número" disabled/>
               </div>
               <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Forma de pagamento</label>
                <input onChange={(e) => setFormapagto(e.target.value)} value={formapagto} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
               <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Data de fechamento</label>
                <input onChange={(e) => setDatafinal(e.target.value)} value={datafinal} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1">Hora de fechamento</label>
                <input onChange={(e) => setHorafinal(e.target.value)} value={horafinal} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled/>
              </div>
              <div className='text-center'>
                <Link to="/app/os" className="btnCancelar btn btn-outline-primary">Voltar</Link>
                <button onClick={(e) => RecPagtoPDF(
                    cliente, 
                    acessorio, 
                    equipamento,
                    numero, 
                    informado,
                    constatado,
                    executado,
                    marca,
                    modelo,
                    serie,
                    datainicial,
                    horainicial,
                    formatter.format(vrproduto),
                    formatter.format(vrservico),
                    formatter.format(deslocamento),
                    formatter.format(desconto),
                    formatter.format(vrtotal),
                    formapagto,
                    datafinal,
                    horafinal
                    )} type="button" className="btnRecibo btn btn-danger">Imprimir Recibo</button>

              </div> 
          </form>
        </div>
      </div>
    </div>;  
  }

export default Recibo;