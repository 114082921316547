import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'


function clientesPDF(clientes){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Clientes',
            fontSize: 15,
            bold: true,
            margin:[15, 20, 0, 45]
        }
    ];

    const dados = clientes.map((cliente) => {
        return [
//            {text: cliente.id, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: cliente.nome, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: cliente.contato, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: cliente.fone, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: cliente.endereco, fontSize: 6, margin: [0, 2, 0, 2]},
            {text: cliente.bairro, fontSize: 6, margin: [0, 2, 0, 2]},
        ]
    });
const details = [
    {

    },
 ];


//    const details = [
//        {
//            table: {
//                headerRows: 1,
//                widths: ['*', '*', '*', '*', '*', '*'],  //    qtde de colunas q conterá o relatório
//                body: [
//                    [
////                        {text: 'Código', style: 'tableHeader', fontSize: 8},
//                        {text: 'Nome', style: 'tableHeader', fontSize: 8},
//                        {text: 'Contato', style: 'tableHeader', fontSize: 8},
//                        {text: 'Telefone', style: 'tableHeader', fontSize: 8},
 //                       {text: 'Endereço', style: 'tableHeader', fontSize: 8},
 //                       {text: 'Bairro', style: 'tableHeader', fontSize: 8}
 //                   ],
 //                   ...dados
 //               ]
 //           },
 //           layout: 'lightHorizontalLines' //headerLineOnly outro tipo de estilo de relatótio
 //       }
 //   ];


 

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin:[0, 10, 20, 0]
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
//        images: {
//            mySuperImage: 'data:image/jpeg;base64,...content...',
        
            // in browser is supported loading images via url (https or http protocol) (minimal version: 0.1.67)
//            snow: 'https://firebasestorage.googleapis.com/v0/b/game-cell.appspot.com/o/logo.png?alt=media&token=c3803afa-35de-400f-ac55-8d12663c4984',
        
            // is supported loading images via url with custom headers (minimal version: 0.2.5)
//            strawberries: {
//              url: 'https://firebasestorage.googleapis.com/v0/b/game-cell.appspot.com/o/logo.png?alt=media&token=c3803afa-35de-400f-ac55-8d12663c4984',
//              headers: {
//                myheader: '123',
//                myotherheader: 'abc',
//              }
//            }
//          },
    
        footer: Rodape
    }

        pdfMake.createPdf(docDefinitios).download();
}

export default clientesPDF;