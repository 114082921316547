import React, { useContext } from 'react';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import { AuthContext } from './app/Context/auth.jsx';

/* Paginas */
import Login from './app/Login/login.jsx';
import NovaConta from './app/NovaConta/novaconta.jsx';
import ResetSenha from './app/ResetSenha/resetsenha.jsx';
import Home from './app/Home/home.jsx';

import Cliente from './app/Cliente/cliente.jsx';
import NovoCliente from './app/NovoCliente/novocliente.jsx';
import EditarCliente from './app/EditarCliente/editarcliente.jsx';

import Atendimento from './app/Atendimento/atendimento.jsx';
import NovoAtendimento from './app/NovoAtendimento/novoatendimento.jsx';
import EditarAtendimento from './app/EditarAtendimento/editaratendimento.jsx';

import Os from './app/Os/os.jsx';
import NovoOs from './app/NovoOs/novoos.jsx';
import EditarOs from './app/EditarOs/editaros.jsx';
import Pgto from './app/Pgto/pgto.jsx';
import Recibo from './app/Recibo/recibo.jsx';

import AtalhoOs from './app/Atalho/atalhoos.jsx';

import UploadsImages from './app/UploadsImages/uploadsimages.jsx';
import Dashboard from './app/Dashboard/dashboard.jsx';

function App(){
    const {logado} = useContext(AuthContext);

    function SecureRoute({...params}){
      if (!logado){
        return <Redirect to="/app" />
      } else {
        return <Route {...params} />
      }
    }

    return <BrowserRouter>
      <Switch>
        <Route exact path='/' component={Login} />    
        <Route exact path='/app' component={Login} />    
        <Route exact path='/app/novaconta' component={NovaConta} />    
        <Route exact path='/app/resetsenha' component={ResetSenha} />    
      
        <SecureRoute exact path='/app/home' component={Home} />
        <SecureRoute exact path='/app/cliente' component={Cliente} />
        <SecureRoute exact path='/app/novocliente' component={NovoCliente} />    
        <SecureRoute exact path='/app/editarcliente/:id' component={EditarCliente} />
        <SecureRoute exact path='/app/atendimento' component={Atendimento} />    
        <SecureRoute exact path='/app/novoatendimento' component={NovoAtendimento} />    
        <SecureRoute exact path='/app/editaratendimento/:id' component={EditarAtendimento} />
        
        <SecureRoute exact path='/app/os' component={Os} />
        <SecureRoute exact path='/app/novoos' component={NovoOs} />    
        <SecureRoute exact path='/app/atalhoos/:id' component={AtalhoOs} />    
        <SecureRoute exact path='/app/editaros/:id' component={EditarOs} />
        <SecureRoute exact path='/app/pgto/:id' component={Pgto} />
        <SecureRoute exact path='/app/recibo/:id' component={Recibo} />

        <SecureRoute exact path='/app/uploadsimages/' component={UploadsImages} />
        <SecureRoute exact path='/app/dashboard/' component={Dashboard} />
      </Switch>     
    </BrowserRouter>;
  }
 
export default App;